import { Container, Paper, Typography } from "@material-ui/core";
import React from "react";

export default function ChatBubble({ userDisplayName, messageContents }) {
  return (
    <Container style={{ padding: 20 }}>
      <Paper style={{ padding: 10 }}>
        <Typography variant="h6">{userDisplayName}</Typography>
        <Typography>{messageContents}</Typography>
      </Paper>
    </Container>
  );
}
