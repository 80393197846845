import React, { useState } from "react";
import {
  Container,
  Paper,
  Typography,
  TextField,
  Button,
} from "@material-ui/core";

export default function Login({ encryptionKey, setEncryptionKey }) {
  const [channelBox, setChannelBox] = useState("");
  const [passwordBox, setPasswordBox] = useState("");
  return (
    <Container style={{ display: "flex", justifyContent: "center" }}>
      <Paper
        style={{
          width: 350,
          height: 350,
          padding: 20,
          margin: 100,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
        }}
      >
        <Typography variant="h4">GraphiteChat</Typography>
        <TextField
          label="Channel ID"
          variant="outlined"
          fullWidth
          value={channelBox}
          onChange={(event) => setChannelBox(event.target.value)}
        />
        <TextField
          label="Password"
          variant="outlined"
          type="password"
          value={passwordBox}
          onChange={(event) => setPasswordBox(event.target.value)}
          fullWidth
        />
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={() => {
            setEncryptionKey(passwordBox);
          }}
        >
          Join Channel
        </Button>
        <Button
          variant="contained"
          color="secondary"
          fullWidth
          onClick={() => {
            setEncryptionKey(passwordBox);
          }}
        >
          Create Channel
        </Button>
      </Paper>
    </Container>
  );
}
