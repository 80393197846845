import React, { useState, useEffect } from "react";
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle,
  IconButton,
  Paper,
  TextField,
} from "@material-ui/core";
import {DeleteForever, Person, Send} from "@material-ui/icons";
import ChatBubble from "components/ChatBubble";
import CryptoJS from "crypto-js";
import io from 'socket.io-client';

const socket = io.connect(process.env.REACT_APP_API_URL);

export default function Chat({encryptionKey, setEncryptionKey}) {
  const [currentDisplayName, setCurrentDisplayName] = useState("John Doe");
  const [messageBox, setMessageBox] = useState("");
  const [chatMessages, setChatMessages] = useState([]);
  const [deleteAllConfirmationOpen, setDeleteAllConfirmationOpen] = useState(
    false
  );

  async function addNewMessage(messageToAdd) {
    await setChatMessages([...chatMessages, messageToAdd]);
    const messagesListElem = document.getElementById("messagesList");
    messagesListElem.scrollTop = messagesListElem.scrollHeight;
  }

  function encryptMessage(messageToEncrypt) {
    const encryptedMessage = CryptoJS.AES.encrypt(JSON.stringify(messageToEncrypt), encryptionKey).toString();
    return encryptedMessage;
  }

  function decryptMessage(messageToDecrypt) {
    const decryptedMessage = CryptoJS.AES.decrypt(messageToDecrypt, encryptionKey).toString(CryptoJS.enc.Utf8);
    return JSON.parse(decryptedMessage);
  }

  function sendMessage() {
    const sentMessageData = {
      userDisplayName: currentDisplayName,
      messageContents: messageBox,
    };
    addNewMessage(sentMessageData);
    const encryptedData = encryptMessage(sentMessageData);
    socket.emit('send', encryptedData);
    setMessageBox("");
  }

  function deleteAllMessages() {
    setChatMessages([]);
  }

  useEffect(() => {
    socket.on('receive', (data) => {
      const decryptedData = decryptMessage(data);
      addNewMessage(decryptedData);
    })
  });

  return (
    <Container style={{ padding: 20 }}>
      <Dialog
        open={deleteAllConfirmationOpen}
        onBackdropClick={() => setDeleteAllConfirmationOpen(false)}
      >
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContentText style={{ paddingLeft: 25, paddingRight: 25 }}>
          Are you sure you want to delete all messages? This cannot be undone!
        </DialogContentText>
        <DialogActions>
          <Button autoFocus onClick={() => setDeleteAllConfirmationOpen(false)}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              setDeleteAllConfirmationOpen(false);
              deleteAllMessages();
            }}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <Paper
        id="messagesList"
        style={{ height: "70vh", marginBottom: 10, overflow: "auto" }}
      >
        {chatMessages.map((message, index) => (
          <ChatBubble {...message} key={index} />
        ))}
      </Paper>
      <Paper style={{ display: "flex" }}>
        <IconButton onClick={() => setCurrentDisplayName('Mike Doe')}>
          <Person />
        </IconButton>
        <TextField
          label="Type a message..."
          variant="filled"
          style={{ flex: 1 }}
          onKeyDown={(event) => {
            if (event.key === "Enter" && messageBox) {
              event.preventDefault();
              event.stopPropagation();
              sendMessage();
            }
          }}
          value={messageBox}
          onChange={(event) => setMessageBox(event.target.value)}
        />
        <IconButton onClick={() => setDeleteAllConfirmationOpen(true)}>
          <DeleteForever />
        </IconButton>
        <IconButton onClick={sendMessage} disabled={!messageBox}>
          <Send />
        </IconButton>
      </Paper>
    </Container>
  );
}
