import React, { useState } from "react";
import "./App.css";
import Chat from "pages/Chat";
import Login from "pages/Login";

export default function App() {
  const [encryptionKey, setEncryptionKey] = useState("");
  return encryptionKey ? (
    <Chat encryptionKey={encryptionKey} setEncryptionKey={setEncryptionKey} />
  ) : (
    <Login encryptionKey={encryptionKey} setEncryptionKey={setEncryptionKey} />
  );
}
